import type { JSX } from 'react';
import { IconsRowWithTitleAndDescription as IRWTD } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type IconsRowWithTitleAndDescriptionProps =
  SliceComponentProps<Content.IconsRowWithTitleAndDescriptionSlice>;

const IconsRowWithTitleAndDescription = ({
  slice,
}: IconsRowWithTitleAndDescriptionProps): JSX.Element => <IRWTD {...slice} />;

export default IconsRowWithTitleAndDescription;
